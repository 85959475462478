<template>
  <div>
    <div id="disbursement-data">
      <v-form ref="form" @submit.prevent="nextCard()">
        <v-main v-show="card_index === card_cbu">
          <v-row align="center" align-content="center">
            <v-col class="col-sm-12 col-md-6 col-12">
              <div>
                <h2>Ingresá el número de CBU de la cuenta</h2>
                <h5 class="subtitle">EN DONDE TE ACREDITAREMOS EL PRÉSTAMO.</h5>
              </div>
              <v-col
                align="center"
                justify="center"
                class="stepper-register__left-section-image"
              >
                <v-img
                  class="imageCircleDesktop"
                  src="@/assets/img/yellow_pet_confidential.svg"
                  alt="logo"
                  contain
                  max-height="300"
                  min-height="300"
                />
              </v-col>
            </v-col>
            <v-col class="col-md-5" align-self="center">
              <v-text-field
                v-model="cbu"
                label="Nro. de CBU"
                hint="Ejemplo: 2323211345676853573886"
                color="#FFDD00"
                type="text"
                maxlength="22"
                counter
                :rules="[rules.counter]"
                @keyup="checkValidCbu()"
                @keypress="isNumber($event)"
              />
              <v-row v-show="cbuValid && cbushowdata" :class="cbuclass">
                <v-col
                  align="left"
                  justify="left"
                  class="text-success ml-2 mr-2"
                >
                  Nombre del Banco: {{ cbudatamessage }} <br />
                  <hr />
                  Atención: Si no coincide, por favor revisá tu CBU.
                </v-col>
              </v-row>
              <v-row v-show="!cbuValid && cbushowdata" :class="cbuclass">
                <v-col
                  align="left"
                  justify="left"
                  class="text-danger ml-2 mr-2"
                >
                  {{ cbudatamessage }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="step-cbu__btn-continue--margin">
            <v-col class="text-md-end text-center" justify="right">
              <v-btn
                bottom
                depressed
                rounded
                x-large
                :disabled="!cbuValid"
                class="btnAmarillo"
                type="submit"
              >
                Continuar
              </v-btn>
            </v-col>
          </v-row>
        </v-main>

        <v-main v-show="card_debit_card === card_index">
          <v-row class="align-center align-content-center">
            <v-col class="col-sm-12 col-md-6 col-12" align-self="center">
              <h2>
                Por último, registrá tu tarjeta de débito para el cobro de las
                cuotas mensuales
              </h2>
              <h4 class="subtitle">
                EL COBRO PROGRAMADO DE CUOTAS MENSUALES SERÁ ENTRE EL 1 Y EL 10
                DE CADA MES.
                <br /><br />
                PARA VALIDAR TU TARJETA NECESITAMOS QUE HAYA AL MENOS $15 EN TU
                CUENTA. NO TE PREOCUPES, NO REALIZAREMOS DÉBITOS SIN TU PERMISO.
              </h4>
            </v-col>
            <v-col class="col-md-6 align-self-center col-sm-12">
              <v-img
                class="ml-auto"
                src="@/assets/img/yellow_pet_card.svg"
                alt="Mascota"
                max-width="80%"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-md-end text-center" justify="right">
              <v-btn
                bottom
                depressed
                rounded
                x-large
                class="btnAmarillo"
                @click="loadMbbx()"
              >
                Continuar
              </v-btn>
            </v-col>
          </v-row>
        </v-main>
      </v-form>
    </div>
    <div id="mbbx-container"></div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import validatorNo from "arg.js";

export default {
  name: "AutogestionDesembolso",
  props: {
    card: { type: Number, default: 1 },
  },
  data: () => ({
    cbushowdata: false,
    cbudatamessage: "",
    cbuclass: "",
    cbuValid: false,
    rules: {
      counter: (value) => value.length <= 22 || "Máximo 22 caracteres",
    },
    form: {
      name: "",
      card_number: "",
      due_date: "",
      security_code: "",
    },
    valid: true,
    cbu: "",
    card_debit_card: 2,
    card_cbu: 1,
    card_index: null,
    editBoardDialog: true,
    subscription: null,
    subscriber: null,
  }),
  mounted() {
    console.log("Componente Desembolso creado");
    this.card_index = this.card;
    this.getInfoMbbx();
  },
  methods: {
    ...mapActions(["setBank", "openLoader", "closeLoader"]),
    ...mapGetters(["getPreffix", "goToCardInput"]),
    back() {
      this.$emit("send-message", "4");
    },
    nextCard() {
      let dataForm = { cbu: this.cbu, bank: this.$store.getters.getBank };
      this.openLoader();
      this.$gtm.trackEvent({
        noninteraction: false,
        event: `${this.getPreffix()}_BANK_CODE`,
        id_person: this.$store.getters.getUserForm,
      });
      this.$apiserver
        .sendFormData(dataForm, this.$apiserver.creditApproval())
        .then(() => {
          if (process.env.VUE_APP_WITH_MOBBEX == 0) {
            this.next();
            return;
          }
          if (!this.goToCardInput()) {
            this.next();
            return;
          }
          this.card_index = this.card_index + 1;
          this.$gtm.trackEvent({
            noninteraction: false,
            event: `${this.getPreffix()}_DEBIT_CARD`,
            id_person: this.$store.getters.getUserForm,
          });
        })
        .catch((error) => {
          this.$store.commit("ERROR_MODAL", true);
          console.warn(error);
        })
        .finally(() => this.closeLoader());
    },
    next() {
      this.$emit("send-message", "7");
    },
    checkValidCbu() {
      let cbu = validatorNo.cbu;
      let length = this.cbu.length;
      this.cbushowdata = true;
      this.cbuValid = false;
      this.cbuclass = "textBank";
      let valid = cbu.isValid(this.cbu);

      if (length === 22 && valid) {
        if (parseInt(this.cbu.toString().substr(0, 3), 10) > 1) {
          this.cbudatamessage = cbu.getAssociatedBank(this.cbu);
          this.cbuValid = true;
          this.setBank(this.cbudatamessage);
        }
      }
      if (length < 22) {
        this.cbudatamessage = "La longitud del CBU es menor a 22 dígitos";
      }
      if (length > 22) {
        this.cbudatamessage = "La longitud del CBU es mayor a 22 dígitos";
      }

      if (
        (!valid || parseInt(this.cbu.toString().substr(0, 3), 10) == 0) &&
        length == 22
      ) {
        this.cbudatamessage =
          "El CBU no pertenece a un Banco registrado, por favor revísalo.";
      }
    },
    loadMbbx() {
      try {
        if (this.subscriber === null && this.subscription === null) {
          this.getInfoMbbx().catch((error) => {
            this.$store.commit("ERROR_MODAL", true);
            console.log(error);
            return;
          });
        }
        let options = {
          id: this.subscription,
          sid: this.subscriber,
          type: "subscriber_source",
          onResult: (data) => {
            if (data.status.code === "200") {
              this.next();
              return;
            }
            window.MobbexEmbed.close();
          },
          onPayment: (data) => {
            console.info("Payment: ", data);
          },
          onOpen: () => {
            console.info("Pago iniciado.");
          },
          onClose: (cancelled) => {
            window.MobbexEmbed.close();
            console.info(`${cancelled ? "Cancelado" : "Cerrado"}`);
          },
          onError: (error) => {
            console.error("ERROR: ", error);
          },
        };
        var mbbxButton = window.MobbexEmbed.init(options);
        mbbxButton.open();
      } catch (e) {
        this.$store.commit("ERROR_MODAL", true);
        console.log(e);
      }
    },
    getInfoMbbx() {
      if (process.env.VUE_APP_WITH_MOBBEX == 1) {
        return this.$apiserver.getPaymentModal().then((response) => {
          this.subscriber = response.subscriber;
          this.subscription = response.subscription;
        });
      }
    },
  },
};
</script>